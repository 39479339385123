import React, {useState, useEffect } from "react"
import {graphql} from 'gatsby';
import Img from 'gatsby-image';
import SEO from "../components/seo";
import {Row, Col} from 'react-bootstrap';

import Layout from '../components/Layout.js';
import Slider from '../components/SliderHome.js';
import arrowDown from '../images/icons/arrowDown.png';
import SplitSection from '../components/SplitSection.js';
import InfoSection from '../components/InfoSection.js';
import InfoAndBackground from "../components/InfoAndBackground";
import AgeGate from "../components/AgeGate";
import Cookies from "js-cookie";

const Budx = ({data,props}) => {

    const [listSlider, setListSlider] = useState([]);
    const [age, setAge] = useState(true);

    useEffect(() => {
        // console.log("ok!")
        let cookie = Cookies.get('age')
        let local = sessionStorage.getItem('age')
        // console.log("Cookie",cookie)
        if (cookie || local) setAge(false)
        setListSlider(data.Slider.edges.sort((a, b) => a.node.position - b.node.position));
        // setListSlider([data.Slider.edges[0].node] );
    },[])

    return (
        !age ? (
        <Layout style={{background: 'black'}}>
            <SEO title="Budx"/>
            <Slider sliders={listSlider}/>
            <Row className="d-flex justify-content-center m-0">
                <Col xs={'auto'}>
                    <a onClick={e => document.querySelector('#InfoSection').scrollIntoView({behavior: 'smooth'})} >
                        <img src={arrowDown} className="ArrowDown"/>
                    </a>
                </Col>
            </Row>
            <SplitSection
                left={<InfoSection
                    title={data.Middle.edges[0].node.title}
                    paragraph={data.Middle.edges[0].node.paragraph}
                    button={data.Middle.edges[0].node.textButton}
                    link={data.Middle.edges[0].node.linkButton}
                />} 
                right={<img src={data.Middle.edges[0].node.image} style={{height: '100%',width: '100%'}}/>}
            />
            <div style={{backgroundColor: '#fff'}}>
                <InfoAndBackground info={data.imageFooter.edges[0].node}/>
            </div>
        </Layout>
        ):(
            <AgeGate path="/"/>
        )
       
    )

}

export default Budx

export const data = graphql`
    query BudXHome {
        allFile(filter: {relativeDirectory: {eq: "Home"}}) {
            edges {
                node {
                    base
                        childImageSharp {
                            fluid (quality: 100, maxWidth: 1920) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                }
            }
        }
        Slider: allBudXSlider {
            edges {
                node {
                    title
                    paragraph
                    textButton
                    linkButton
                    position
                    imageDesktop
                    imageMobile
                }
            }
        }
        Middle: allBudXMiddle {
            edges {
                node {
                    title
                    paragraph
                    textButton
                    linkButton
                    image
                }
            }
        }
        imageFooter: allBudXBottom {
            edges {
                node {
                    title
                    paragraph
                    textButton
                    linkButton
                    image
                }
            }
        }
    }`
;
